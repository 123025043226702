import { useEffect } from 'react';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import localFont from 'next/font/local';
import Head from 'next/head';

import { PageProps } from '@hultafors/hultaforsgroup/types';
import '@hultafors/shared/style/main.scss';
import './global.scss';
import './typography.scss';
import './layout.scss';

export { reportWebVitals } from '@hultafors/shared/helpers';

const AppProvider = dynamic(() =>
  import('@hultafors/hultaforsgroup/context').then((mod) => mod.AppProvider),
);
const Footer = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Footer),
);
// const GlobalStyle = dynamic(() =>
//   import('@hultafors/hultaforsgroup/components').then((mod) => mod.GlobalStyle)
// );
const Nav = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Nav),
);

const franklinGothic = localFont({
  src: [
    {
      path: '../fonts/FranklinGothic-Book.woff',
      style: 'normal',
      weight: '300',
    },
    {
      path: '../fonts/FranklinGothic-Medium.woff',
      style: 'bold',
      weight: '400',
    },
  ],
  variable: '--font-franklin-gothic',
});

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const { globalContent, settings, menu, featureToggle, footer, ...restProps } =
    pageProps;
  const isCampaignPage = router.asPath.startsWith('/campaign');

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-franklin-gothic: ${franklinGothic.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AppProvider
        value={{ featureToggle, footer, globalContent, menu, settings }}
      >
        {!isCampaignPage && <Nav />}
        <Component {...pageProps} />
        <GoogleTagManager gtmId="GTM-PL8PKQ" />
        {!isCampaignPage && <Footer />}
        <div id="portal"></div>
      </AppProvider>
    </>
  );
};

export default CustomApp;
