import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { RouterLink } from '../router-link/router-link';

interface MenuProps {
  $menu?: boolean;
}

const AccordionLinkShared = css<MenuProps>`
  display: flex;
  align-items: center;
  border-top: thin solid ${colors.gray4};
  border-top: ${({ $menu }) => ($menu ? '0' : `thin solid ${colors.gray4}`)};
  width: 100%;
  padding-block: ${({ $menu }) => ($menu ? '8px' : '1.5rem')};
  padding-inline: ${({ $menu }) => ($menu ? spacing.small : '0')};
  min-height: 48px;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const AccordionLinkStyled = styled(RouterLink)<MenuProps>`
  ${AccordionLinkShared};
`;

export const AccordionItemStyled = styled(AccordionItem)`
  position: relative;
  width: 100%;
`;

export const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
  width: 100%;
`;

export const ButtonInner = styled.span<MenuProps>`
  position: relative;
  ${AccordionLinkShared};
`;

export const AccordionItemButtonStyled = styled(AccordionItemButton)<{
  $menu?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;

  ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 20px;
  }
  ${AccordionItemStyled} ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 40px;
  }

  > svg {
    display: block;
    position: absolute;
    inset-inline-end: ${({ $menu }) => ($menu ? spacing.small : 0)};
    inset-block-start: calc(50% - 8px);
    transition: all 0.1s ease-out;
    inline-size: 16px;
    block-size: 16px;
    transform-origin: 8px 8px;
  }

  &[aria-expanded='true'] {
    > svg {
      transform: rotate(45deg);
    }
  }
`;

interface AccordionItemPanelStyledProps {
  $height?: number;
}

export const AccordionItemPanelStyled = styled(
  AccordionItemPanel,
)<AccordionItemPanelStyledProps>`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &[hidden] {
    display: block;
  }
`;

export const AccordionItemContent = styled.div<MenuProps>`
  padding-block: 8px;
  ${({ $menu }) =>
    $menu &&
    css`
      display: flex;
      flex-direction: column;
      gap: ${spacing.xsmall};
    `}
`;
