import { Fragment } from 'react';

import ExternalSvg from '../../svg/external.svg';
import MailSvg from '../../svg/mail.svg';
import PhoneSvg from '../../svg/phone.svg';
import TagSvg from '../../svg/tag.svg';
import { TextButton } from '../text-button/text-button';

import {
  Address,
  LeftIconLink,
  StoreItemStyled,
  ViewOnMap,
} from './store-item.styled';

interface StoreItemProps {
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  mapLink?: string;
  view_on_map?: string | null | undefined;
  email?: string;
  phone?: string;
  websiteUrl?: string;
  visit_web_site?: string | null | undefined;
  local_web?: string;
  openingHours?: string;
}

export const StoreItem: React.FC<StoreItemProps> = (props) => {
  const {
    address,
    zipCode,
    city,
    country,
    mapLink,
    view_on_map,
    email,
    phone,
    websiteUrl,
    visit_web_site,
  } = props;

  const addressParts = [
    address,
    [zipCode, city].filter(Boolean).join(' '),
    country,
  ].filter(Boolean);
  const rowMapper = (row: string, index: number, all: string[]) => {
    return (
      <Fragment key={`row-${index}-${row}`}>
        {row}
        {index < all.length - 1 && <br />}
      </Fragment>
    );
  };
  return (
    <StoreItemStyled>
      <Address>{addressParts.map(rowMapper)}</Address>

      {view_on_map && mapLink && (
        <ViewOnMap>
          <LeftIconLink
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TagSvg
              width={16}
              height={16}
              aria-hidden={true}
              focusable={false}
            />

            {view_on_map}
          </LeftIconLink>
        </ViewOnMap>
      )}

      {(email || phone) && (
        <div className="Contact EmailAndPhoneWrapper">
          {email && (
            <div className="Email">
              <LeftIconLink href={`mailto:${email}`}>
                <MailSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
                {email}
              </LeftIconLink>
            </div>
          )}
          {phone && (
            <div className="Phone">
              <LeftIconLink href={`tel:${phone}`}>
                <PhoneSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
                {phone}
              </LeftIconLink>
            </div>
          )}
        </div>
      )}

      {websiteUrl && (
        <div className="Contact VisitSite">
          <TextButton
            to={websiteUrl}
            icon={
              <ExternalSvg
                width={16}
                height={16}
                aria-hidden={true}
                focusable={false}
              />
            }
            iconSize={16}
          >
            {visit_web_site}
          </TextButton>
        </div>
      )}
    </StoreItemStyled>
  );
};
