import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { Box } from '../box/box';

interface DialogWrapperProps {
  $top?: CSSProperties['top'];
  $right?: CSSProperties['right'];
  $bottom?: CSSProperties['bottom'];
  $left?: CSSProperties['left'];
  $height?: CSSProperties['height'];
  $width?: CSSProperties['width'];
}

export const DialogWrapper = styled(Box)<DialogWrapperProps>`
  position: fixed;
  inset: ${({ $top = 0 }) => $top} ${({ $right = 0 }) => $right}
    ${({ $bottom = 0 }) => $bottom} ${({ $left = 0 }) => $left};
  margin: auto;
  height: ${({ $height = '100%' }) => $height};
  width: ${({ $width = '100%' }) => $width};
  z-index: 10;
  box-sizing: border-box;
  color: ${colors.HGBlack};
  background-color: ${colors.HGWhite};
  overflow-y: auto;
`;

interface DialogHeaderProps {
  $isFullscreen?: boolean;
}

export const DialogHeader = styled.div<DialogHeaderProps>`
  width: auto;
  position: absolute;
  top: ${spacing.small};
  right: ${spacing.small};
  z-index: 10;

  ${({ $isFullscreen }) =>
    $isFullscreen &&
    css`
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        inset-block-start: ${spacing.regular};
        inset-inline-end: ${spacing.medium};
      }
    `};

  button {
    padding: 0;
    inline-size: 40px;
    block-size: 40px;
    min-block-size: 40px;

    img {
      block-size: 24px;
      inline-size: 24px;
    }
  }
`;

export const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0 ${spacing.medium};
`;
