import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { EmployeeFragment } from '@hultafors/hultaforsgroup/types';

import {
  Description,
  EmployeeWrapper,
  ImageWrapper,
  IntroHeader,
  Left,
  Name,
  Right,
  Title,
  TitleNameWrapper,
} from './employee.styled';

type EmployeeProps = EmployeeFragment;

export const Employee: React.FC<EmployeeProps> = ({
  title,
  introText,
  description,
  name,
  profileImage,
}) => {
  const sizes = [
    `(min-width: ${breakpoints.mobileMax}) 160px`,
    `(min-width: ${breakpoints.mediumMobileMax}) 375px`,
    '100vw',
  ].join(', ');
  return (
    <EmployeeWrapper>
      {profileImage?.responsiveImage?.src && (
        <Left>
          <ImageWrapper>
            <Image
              src={profileImage.responsiveImage.src}
              alt={profileImage.alt || name || ''}
              blurDataURL={profileImage.responsiveImage?.base64 || ''}
              placeholder={
                profileImage.responsiveImage?.base64 ? 'blur' : 'empty'
              }
              sizes={sizes}
              fill
            />
          </ImageWrapper>
        </Left>
      )}
      <Right>
        {title && name && (
          <TitleNameWrapper>
            <Name>{name}</Name>
            <Title>{title}</Title>
          </TitleNameWrapper>
        )}
        {introText && <IntroHeader>{introText}</IntroHeader>}
        {description && <Description>{description}</Description>}
      </Right>
    </EmployeeWrapper>
  );
};
